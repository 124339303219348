<template src="./resendmoneytransfer.html"></template>
<style scoped src="./resendmoneytransfer.css"></style>
<script>
import transactionService from '@/services/TransactionService.vue'
import transactionConstants from '@/constants/TransactionConstants.vue'
import accountConstants from '@/constants/AccountConstants.vue'
import loading from 'vue-loading-overlay'
import moment from 'moment'
import { requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import modal from '@/components/modal/notification/modal'
import utils from '@/utils/utils.vue'
 import pagination from '@/components/vtable/vtpagination.vue'

export default {
    components : {
        loading, modal, pagination
    },
    data () {
        return {
            transactionSummary : [],
            countryList :accountConstants.countryList,
            destinationCurrency : '',
            transactionStatus : null,
            customerEmail :null,
            fromDate :null,
            toDate :null,
            currency: {},
            loaderVisible : false ,
            errorMessages: applicationConstants.formsMessages,
            columns: ['DateTime','SenderName','SenderEmail','ParentReferenceNumber','ReferenceNumber','RecipientName','TransactionStatus','Action'],
            options :{
                headings : {
                DateTime : 'Date Time',
                SenderName : 'Sender Name',
                SenderEmail : 'Sender Email',
                ParentReferenceNumber :'Parent Reference Number',
                RecipientName : 'Recipient Name',
                TransactionStatus : 'Transaction Status',
                ReferneceNumber : 'Reference Number'
                },
                pagination: {
                    edge: false
                }
            },
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
           },
           enableLazyLoading: applicationConstants.enableLazyLoading
        }
    },
    created () {
        let desCurrency =[]
        this.countryList.forEach(country => desCurrency.push(country.Currency))
        this.currency = Array.from(new Set(desCurrency))
        if(this.$route.params.resendMoneyTransferParams) {
            this.customerEmail = this.$route.params.resendMoneyTransferParams.customerEmail
            this.destinationCurrency = this.$route.params.resendMoneyTransferParams.destinationCurrency
            this.transactionStatus = this.$route.params.resendMoneyTransferParams.transactionStatus
            this.fromDate = this.$route.params.resendMoneyTransferParams.fromDate
            this.toDate = this.$route.params.resendMoneyTransferParams.toDate
            if( this.customerEmail || this.destinationCurrency != '' || this.transactionStatus
            || this.fromDate || this.toDate) {
                this.showTransaction()
            }else{
                this.showAll()
            }
        }
    },
    methods : {
        async showAll () {
            try {
                this.$v.$reset()
                this.loaderVisible = true
                this.transactionStatus = null
                this.customerEmail = null
                this.destinationCurrency =''
                this.fromDate = null
                this.toDate = null
                await transactionService.methods.GetTransactionSummary({TransactionType : '3'})
                .then(res => {
                    this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                    if(this.transactionSummary) {
                        this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                    }else{
                        this.transactionSummary = []
                    }
                    if(this.enableLazyLoading) {
                        this.$refs.paginationRef.selectedPage = 1
                    }
                })
                .catch(err =>{
                    this.alertObject = err
                    this.$bvModal.show(this.$refs.childModal.id)
                }) 
                this.loaderVisible=false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async showTransaction() {
            try {
                this.loaderVisible = true
                let fDate = null, tDate = null, dateValidation = true
                this.fromDate = this.fromDate === '' ? null : this.fromDate
                this.toDate = this.toDate === '' ? null : this.toDate
                if(this.fromDate && this.toDate) {
                    fDate = utils.methods.LocalToUTC(moment(moment(this.fromDate)._d).format('MM-DD-YYYY 00:00:00'))
                    tDate = utils.methods.LocalToUTC(moment(moment(this.toDate)._d).format('MM-DD-YYYY 23:59:59'))
                    dateValidation = new Date(fDate) > new Date(tDate) ? false : true
                }else if ((!this.fromDate && this.toDate) || (this.fromDate && !this.toDate)){
                    this.$v.$touch()
                    dateValidation = false
                }
                if (dateValidation){
                    this.transactionStatus = this.transactionStatus === 'null' ? null : this.transactionStatus
                    await transactionService.methods.GetTransactionSummary({CustomerEmail : this.customerEmail,TransactionType : '3',TransactionStatus : this.transactionStatus,TransactionDate: fDate,TransactionDateTo: tDate})
                    .then(res => {
                        this.transactionSummary = res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem
                        if(this.transactionSummary) {
                            this.transactionSummary = Array.isArray(this.transactionSummary) ? this.transactionSummary : [this.transactionSummary]
                        }else{
                            this.transactionSummary = [] 
                        }
                        this.transactionSummary = this.destinationCurrency ? this.transactionSummary.filter(el=> el.TargetCurrency._text === this.destinationCurrency) : this.transactionSummary 
                        if(this.enableLazyLoading) {
                            this.$refs.paginationRef.selectedPage = 1
                        }
                    })
                    .catch(err => {
                        this.alertObject = err
                        this.$bvModal.show(this.$refs.childModal.id)
                    })
                    this.$v.$reset()
                } else {
                    if(fDate > tDate){
                        this.showAlert(null, 'From Date should be less than toDate')
                    }
                }
                this.loaderVisible=false
            } catch (error) {
                this.loaderVisible = false
            }
        },
        async reSendMoney(row) {
            this.$router.push({
                name : 'SendMoneyForCustomer',
                params : {
                    email : row.SenderEmail,
                    TransactionId: row.TransactionId,
                    ParentReferenceNumber: row.ParentReferenceNumber,
                    type: 'resendmoneytransfer',
                    resendMoneyTransferParams : {
                        customerEmail : this.customerEmail,
                        destinationCurrency : this.destinationCurrency,
                        transactionStatus : this.transactionStatus,
                        fromDate : this.fromDate,
                        toDate : this.toDate
                    }
                }
            })
        },
        removeUnderscoreText(data) {
            if(data)  {
                data = Array.isArray(data) ? data : [data]
                data.forEach(item => Object.keys(item).map(key => item[key] = item[key]?._text))
            }else{
                data=data? data : []
            }
            return data
        },
       showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    computed : {
        today : () => new Date(),
        transactionSummaryTable() {
            let  reducer = (accumulator, currentValue) => {
                    return (accumulator === currentValue?.Value) ? currentValue?.Text : accumulator
                }, transactionSummary = []
            if(this.transactionSummary.length > 0) {
                transactionSummary = JSON.parse(JSON.stringify(this.transactionSummary))
                if(this.enableLazyLoading) {
                    let selectedPage = this.$refs.paginationRef.selectedPage
                    transactionSummary = (JSON.parse(JSON.stringify(this.transactionSummary))).slice((selectedPage-1) * 10, selectedPage * 10)
                }
                transactionSummary = this.removeUnderscoreText(JSON.parse(JSON.stringify(transactionSummary)))
                transactionSummary.forEach(el => {
                    el.TransactionStatus = transactionConstants.transactionStatus.reduce(reducer,el.TransactionStatus)
                    el.DateTime = utils.methods.UTCtoLocal(moment(moment(el.DateTime, 'MM/DD/YYYY hh:mm:ss')._d).format('MM-DD-YYYY HH:mm:ss'))
                })
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.transactionSummary?.length ?? 0
                }
            }
            this.enableLazyLoading ? updateTotalRecords() : null
            return transactionSummary
        }
    },
    validations () {
        this.fromDate = this.fromDate === '' ? null : this.fromDate
        this.toDate = this.toDate === '' ? null : this.toDate
        return {
            fromDate : {
                required: requiredIf(function () {
                    return !this.fromDate && this.toDate
                })
            },
            toDate : {
                required: requiredIf(function () {
                    return this.fromDate && !this.toDate
                })
            }
        }
    }
}
</script>